<template>
  <v-card class="pa-3 pt-0 mt-2 pb-0" outlined>
    <v-card-text>
      <input type="hidden" name="expenseId" :value="expenseId" />
      <v-row class="mt-1">
        <v-col cols="12" sm="6" class="pa-0 px-sm-2 mb-1">
          <v-select
            v-model="patientName"
            :items="dependents"
            :rules="[(v) => !!v || 'Please select']"
            label="Patient Name"
            name="PatientName"
            dense
            required
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0 px-sm-2 mb-1">
          <v-menu
            v-model="showDatepicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Date of Expense"
                name="expenseDate"
                prepend-icon="mdi-calendar"
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="showDatepicker = false"
              :min="minDate"
              :max="maxDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pa-0 px-sm-2">
          <v-select
            v-model="coverageType"
            :items="coverages"
            item-text="name"
            :rules="[(v) => !!v || 'Please select coverage']"
            label="Available Coverage"
            name="coverageType"
            dense
            required
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0 px-sm-2">
          <v-text-field
            v-model="amount"
            :rules="[(v) => !!v || 'Please enter amount']"
            label="Amount of this expense"
            name="Amount"
            type="number"
            placeholder="00.00"
            prepend-icon="mdi-currency-usd"
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Massage and Physo note -->
      <v-row v-if="planNumber === '110' && dateNow < firstApril">
        <label>
          Effective April 1, 2023 referral information will be required for
          Physio and Massage Therapist.
        </label>
      </v-row>

      <!-- Referrral info if required -->
      <v-row v-if="requiresReferral">
        <label class="red--text mb-2">
          This Coverage requires Referral. Please indicate physician's name,
          registration number and the referal date.
        </label>
        <!-- <v-col cols="12" sm="6" class="pa-0 px-sm-2">
          <v-file-input
            accept="image/png, image/jpeg, image/bmp, application/pdf"
            v-model="referralFile"
            :rules="fileRules"
            label="Upload referral for this coverage if not already uploaded"
            name="referral"
            type="file"
            placeholder="click to choose image or pdf less than 5MB"
            prepend-icon="mdi-file-upload-outline"
            dense
          ></v-file-input>
        </v-col> -->

        <v-col cols="12" sm="4" class="pa-0 px-sm-2 mb-1">
          <v-menu
            v-model="showDatepickerReferral"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="referralDate"
                label="Date of Referral"
                name="referralDate"
                prepend-icon="mdi-calendar"
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="referralDate"
              @input="showDatepickerReferral = false"
              :max="maxDate"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="4" class="pa-0 px-sm-2">
          <v-text-field
            v-model="physician"
            :rules="[(v) => !!v || 'Please enter name']"
            label="Physician's name"
            name="physician"
            type="text"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="pa-0 px-sm-2">
          <v-text-field
            v-model="physicianRegNumber"
            :rules="[(v) => !!v || 'Please enter number']"
            label="Registration Number"
            name="physicianRegNumber"
            type="text"
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <slot></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["dependents", "coverages", "expenseId", "planNumber"],
  data() {
    return {
      physician: "",
      physicianRegNumber: "",
      showDatepicker: false,
      showDatepickerReferral: false,
      patientName: "",
      coverageType: "",
      firstApril: new Date(2023, 3, 1),
      dateNow: new Date(Date.now()),
      fileRules: [
        (value) =>
          value ||
          value.size < 10000000 ||
          "Please upload referral proof less than 5MB",
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      amount: "",
      referralFile: [],
      referralDate: "",
    };
  },
  mounted() {
    this.patientName = this.dependents[0];
    this.coverageType = this.coverages[0].name;
  },
  watch: {
    dependents: {
      handler() {
        this.patientName = this.dependents[0];
        this.coverageType = this.coverages[0].name;
      },
    },
  },
  computed: {
    requiresReferral() {
      const isReferralRequired = this.coverages.filter(
        (c) => c.name == this.coverageType
      )[0];
      const requiresReferral = isReferralRequired
        ? isReferralRequired.requiresReferral
        : false;
      return requiresReferral;
    },
    minDate() {
      const date = new Date();
      var minDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 30
      );
      return minDate.toISOString().slice(0, 10);
    },
    maxDate() {
      const date = new Date();
      var maxDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      return maxDate.toISOString().slice(0, 10);
    },
  },
};
</script>
